import React, { useEffect, useRef } from "react";
import "./Image.css";
import { disableRightClick } from "../../utils";

function Image(props) {
  const { src, ...rest } = props;
  // //   const imgRef = useRef(null);

  //   useEffect(() => {
  //     const preventLongPress = (e) => {
  //       e.preventDefault();
  //     };

  //     const images = document.querySelectorAll('.img.image');

  //     images.forEach((img) => {
  //       img.addEventListener('touchstart', preventLongPress);
  //       img.addEventListener('contextmenu', preventLongPress);
  //     });

  //     // // Use Intersection Observer to lazy load the image when it comes into view
  //     // const observer = new IntersectionObserver(
  //     //   (entries) => {
  //     //     entries.forEach((entry) => {
  //     //       if (entry.isIntersecting) {
  //     //         // Load the image
  //     //         imgRef.current.src = src;

  //     //         // Stop observing once the image is loaded
  //     //         observer.unobserve(imgRef.current);
  //     //       }
  //     //     });
  //     //   },
  //     //   { threshold: 0.01 } // Adjust the threshold as needed
  //     // );

  //     // // Start observing the image element
  //     // observer.observe(imgRef.current);

  //     // // Clean up event listeners and observer when the component is unmounted
  //     // return () => {
  //     //   images.forEach((img) => {
  //     //     img.removeEventListener('touchstart', preventLongPress);
  //     //     img.removeEventListener('contextmenu', preventLongPress);
  //     //   });

  //     // //   observer.disconnect();
  //     // };
  //   }, [src]); // Depend on src to re-run the effect when the image source changes

  // // //   lazyloading
  // //   return <img ref={imgRef} className="img image" alt="" {...rest} />;
  // // //   regularloading
  // //   return <img className="img image" src={src} alt="" {...rest} />;

  return (
    <div
      className="img bg-img"
      style={{ backgroundImage: `url(${src})` }}
      {...rest}
      onContextMenu={disableRightClick}
    />
  );
  // return <img className="img bg-img" src={src} alt="" onContextMenu={{disableRightClick}} loading="lazy"/>
}

export default Image;

// import React from "react"
// import "./Image.css";
// import { disableRightClick } from "../../utils";

// function Image(props) {
//     const { classes, src, ...rest} = props
//     return (
//             <div className="img bg-img" style={{backgroundImage: `url(${src})`}} {...rest} onContextMenu={disableRightClick}/>
//     )
// }

// export default Image
